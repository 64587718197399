import * as actionTypes from '../../actions/actionTypes'
import { updateObject } from '../utility'
// **  Initial State
const initialState = {
  inscriptions: {},
  error: null,
  loading: false
}

const addInscriptionStart = (state, action) => {
  return updateObject(state, {error: null, loading: true})
}

const addInscriptionSuccess = (state, action) => {
  //console.log(action)
  return updateObject(state, {
    error: null,
    loading: false,
    inscriptions: action.inscriptions})
}

const addInscriptionFail = (state, action) => {
  return updateObject(state, {error: action.error, loading: false})
}

const inscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_INSCRIPTION_START: return addInscriptionStart(state, action)
    case actionTypes.ADD_INSCRIPTION_SUCCESS: return addInscriptionSuccess(state, action)
    case actionTypes.ADD_INSCRIPTION_FAIL: return addInscriptionFail(state, action)
    case actionTypes.UPDATE_INSCRIPTION_START: return addInscriptionStart(state, action)
    case actionTypes.UPDATE_INSCRIPTION_SUCCESS: return addInscriptionSuccess(state, action)
    case actionTypes.UPDATE_INSCRIPTION_FAIL: return addInscriptionFail(state, action)
    case actionTypes.DELETE_INSCRIPTION_START: return addInscriptionStart(state, action)
    case actionTypes.DELETE_INSCRIPTION_SUCCESS: return addInscriptionSuccess(state, action)
    case actionTypes.DELETE_INSCRIPTION_FAIL: return addInscriptionFail(state, action)
    default:
      return state
  }
}

export default inscriptionReducer