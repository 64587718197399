import * as actionTypes from '../../actions/actionTypes'
import { updateObject } from '../utility'
// **  Initial State
const initialState = {
  error: null,
  loading: false
}

const linkInsStart = (state, action) => {
  return updateObject(state, {error: null, loading: true})
}

const linkInsSuccess = (state, action) => {
  //console.log(action)
  return updateObject(state, {
    error: null,
    loading: false})
}

const linkInsFail = (state, action) => {
  return updateObject(state, {error: action.error, loading: false})
}

const inscriptionscompetitionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LINK_INSCRIPTION_START: return linkInsStart(state, action)
    case actionTypes.LINK_INSCRIPTION_SUCCESS: return linkInsSuccess(state, action)
    case actionTypes.LINK_INSCRIPTION_FAIL: return linkInsFail(state, action)
    default:
      return state
  }
}

export default inscriptionscompetitionsReducer