// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import customer from './customer'
import establishment from './establishment'
import competitions from './competitions'
import inscriptions from './inscriptions'
import setcompetitions from './setCompetitions'
import target from './target'
import datastore from './datastore'
import inscriptionscompetitions from './inscriptionscompetitions'
import navbar from './navbar'
import layout from './layout'

//exportar los estados a global
const rootReducer = combineReducers({
  auth,
  customer,
  establishment,
  competitions,
  inscriptions,
  inscriptionscompetitions,
  target,
  navbar,
  setcompetitions,
  datastore,
  layout
})

export default rootReducer
