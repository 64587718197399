import { Ability } from '@casl/ability'
import { initialAbility } from './initialAbility'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userData = JSON.parse(localStorage.getItem('userData'))

//console.log(userData.ability, 'Ability')

//const existingAbility = userData ? userData.ability : null

const existingAbility = [{action: 'manage', subject: 'all'}]
console.log(existingAbility, 'Ability')

export default new Ability(existingAbility || initialAbility)
