import * as actionTypes from '../../actions/actionTypes'
import { updateObject } from '../utility'
// **  Initial State
const initialState = {
  userData: {},
  error: null,
  loading: false
}

const getCustomerStart = (state, action) => {
  return updateObject(state, {error: null, loading: true})
}

const getCustomerSuccess = (state, action) => {
  //console.log(action)
  return updateObject(state, {
    userData: action.userData,
    error: null,
    loading: false})
}

const getCustomerFail = (state, action) => {
  return updateObject(state, {error: action.error, loading: false})
}

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CUSTOMER_START: return getCustomerStart(state, action)
    case actionTypes.GET_CUSTOMER_SUCCESS: return getCustomerSuccess(state, action)
    case actionTypes.GET_CUSTOMER_FAIL: return getCustomerFail(state, action)
    default:
      return state
  }
}

export default customerReducer