import * as actionTypes from '../../actions/actionTypes'
import { updateObject } from '../utility'
// **  Initial State
const initialState = {
  establishmentData: {},
  error: null,
  loading: false
}

const getEstablishmentStart = (state, action) => {
  return updateObject(state, {error: null, loading: true})
}

const getEstablishmentSuccess = (state, action) => {
  //console.log(action)
  return updateObject(state, {
    establishmentData: action.establishmentData,
    error: null,
    loading: false})
}

const getEstablishmentFail = (state, action) => {
  return updateObject(state, {error: action.error, loading: false})
}

const establishmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ESTABLISHMENT_START: return getEstablishmentStart(state, action)
    case actionTypes.GET_ESTABLISHMENT_SUCCESS: return getEstablishmentSuccess(state, action)
    case actionTypes.GET_ESTABLISHMENT_FAIL: return getEstablishmentFail(state, action)
    default:
      return state
  }
}

export default establishmentReducer