/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:178c61f4-ee11-43fd-bb02-237be8c877d6",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_x5ooTRDmB",
    "aws_user_pools_web_client_id": "2n4ljupee5vh2nj982lo30gduj",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "EMAIL"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://kgw3vlnjd5gp3mlct6p4mv6wa4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-r77xwvyul5hd5n2meoci73hfcq",
    "aws_user_files_s3_bucket": "trophyhound980e38e862a54fa882580fb949970eef172709-staging",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_mobile_analytics_app_id": "2629376411674196b0daa4428494edc1",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
