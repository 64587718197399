import * as actionTypes from '../../actions/actionTypes'
import { updateObject } from '../utility'
// **  Initial State
const initialState = {
  userData: {},
  error: null,
  loading: false
}

const addTargetStart = (state, action) => {
  return updateObject(state, {error: null, loading: true})
}

const addTargetSuccess = (state, action) => {
  //console.log(action)
  return updateObject(state, {
    error: null,
    loading: false})
}

const addTargetFail = (state, action) => {
  return updateObject(state, {error: action.error, loading: false})
}

const targetReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_TARGET_START: return addTargetStart(state, action)
    case actionTypes.ADD_TARGET_SUCCESS: return addTargetSuccess(state, action)
    case actionTypes.ADD_TARGET_FAIL: return addTargetFail(state, action)
    case actionTypes.UPDATE_TARGET_START: return addTargetStart(state, action)
    case actionTypes.UPDATE_TARGET_SUCCESS: return addTargetSuccess(state, action)
    case actionTypes.UPDATE_TARGET_FAIL: return addTargetFail(state, action)
    case actionTypes.DELETE_TARGET_START: return addTargetStart(state, action)
    case actionTypes.DELETE_TARGET_SUCCESS: return addTargetSuccess(state, action)
    case actionTypes.DELETE_TARGET_FAIL: return addTargetFail(state, action)
    default:
      return state
  }
}

export default targetReducer