//Authentication  ACTIONS----------------------------------------------------------------------------------------------------------
//Acciones para el sign in
export const AUTH_START = 'AUTH_START'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAIL = 'AUTH_FAIL'

export const STATE_CLEAN = 'STATE_CLEAN'

export const STATUS_EMAIL_SUCCESS = 'STATUS_EMAIL_SUCCESS'

//Competition ACTIONS----------------------------------------------------------------------------------------------------------
export const COMPETITION_START = 'COMPETITION_START'
export const COMPETITION_SUCCESS = 'COMPETITION_SUCCESS'
export const COMPETITION_FAIL = 'COMPETITION_FAIL'
//CUSTOMER ACTIONS----------------------------------------------------------------------------------------------------------
export const GET_CUSTOMER_START = 'GET_CUSTOMER_START'
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS'
export const GET_CUSTOMER_FAIL = 'GET_CUSTOMER_FAIL'

// ESTABLISHMENT ACTIONS ---------------------------------------------------------------------------------------------------------
export const GET_ESTABLISHMENT_START = 'GET_ESTABLISHMENT_START'
export const GET_ESTABLISHMENT_SUCCESS = 'GET_ESTABLISHMENT_SUCCESS'
export const GET_ESTABLISHMENT_FAIL = 'GET_ESTABLISHMENT_FAIL'

//INSCRIPTIONS--------------------------------------------------------------------------------------------------------------------
export const ADD_INSCRIPTION_START = 'ADD_INSCRIPTION_START'
export const ADD_INSCRIPTION_SUCCESS = 'ADD_INSCRIPTION_SUCCESS'
export const ADD_INSCRIPTION_FAIL = 'ADD_INSCRIPTION_FAIL'

export const UPDATE_INSCRIPTION_START = 'UPDATE_INSCRIPTION_START'
export const UPDATE_INSCRIPTION_SUCCESS = 'UPDATE_INSCRIPTION_SUCCESS'
export const UPDATE_INSCRIPTION_FAIL = 'UPDATE_INSCRIPTION_FAIL'

export const DELETE_INSCRIPTION_START = 'DELETE_INSCRIPTION_START'
export const DELETE_INSCRIPTION_SUCCESS = 'DELETE_INSCRIPTION_SUCCESS'
export const DELETE_INSCRIPTION_FAIL = 'DELETE_INSCRIPTION_FAIL'

//INSCRIPTIONCOMPETITION------------------------------------------------------------------------------------------------------------
export const LINK_INSCRIPTION_START = 'LINK_INSCRIPTION_START'
export const LINK_INSCRIPTION_SUCCESS = 'LINK_INSCRIPTION_SUCCESS'
export const LINK_INSCRIPTION_FAIL = 'LINK_INSCRIPTION_FAIL'

//TARGET-----------------------------------------------------------------------------------------------------------------------------
export const ADD_TARGET_START = 'ADD_TARGET_START'
export const ADD_TARGET_SUCCESS = 'ADD_TARGET_SUCCESS'
export const ADD_TARGET_FAIL = 'ADD_TARGET_FAIL'

export const UPDATE_TARGET_START = 'UPDATE_TARGET_START'
export const UPDATE_TARGET_SUCCESS = 'UPDATE_TARGET_SUCCESS'
export const UPDATE_TARGET_FAIL = 'UPDATE_TARGET_FAIL'

export const DELETE_TARGET_START = 'DELETE_TARGET_START'
export const DELETE_TARGET_SUCCESS = 'DELETE_TARGET_SUCCESS'
export const DELETE_TARGET_FAIL = 'DELETE_TARGET_FAIL'

export const ADD_S3_START = 'ADD_S3_START'
export const ADD_S3_SUCCESS = 'ADD_S3_SUCCESS'
export const ADD_S3_FAIL = 'ADD_S3_FAIL'

export const SET_COMPETITION_START = 'SET_COMPETITION_START'
export const SET_COMPETITION_SUCCESS = 'SET_COMPETITION_SUCCESS'
export const SET_COMPETITION_FAIL = 'SET_COMPETITION_FAIL'

//-----------------------------------------DTASTORE-------------------------------
export const DATASTORE_READY = 'DATASTORE_READY'
export const DATASTORE_OUTBOX = 'DATASTORE_OUTBOX'
export const DATASTORE_NETWORK = 'DATASTORE_NETWORK'
export const DATASTORE_LOADING = 'DATASTORE_LOADING'
export const DATASTORE_MUTATION = 'DATASTORE_MUTATION'
