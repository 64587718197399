import * as actionTypes from '../../actions/actionTypes'
import { updateObject } from '../utility'
// **  Initial State
const initialState = {
  setCompetitions: {},
  error: null,
  loading: false
}

const setCompetitionStart = (state, action) => {
  return updateObject(state, {error: null, loading: true})
}

const setCompetitionSuccess = (state, action) => {
  //console.log(action)
  return updateObject(state, {
    error: null,
    loading: false,
    setCompetitions: action.setcompetitions})
}

const setCompetitionFail = (state, action) => {
  return updateObject(state, {error: action.error, loading: false})
}

const inscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_COMPETITION_START: return setCompetitionStart(state, action)
    case actionTypes.SET_COMPETITION_SUCCESS: return setCompetitionSuccess(state, action)
    case actionTypes.SET_COMPETITION_FAIL: return setCompetitionFail(state, action)
    default:
      return state
  }
}

export default inscriptionReducer