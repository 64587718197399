import * as actionTypes from '../../actions/actionTypes'
import { updateObject } from '../utility'


// **  Initial State aqui se establecen las variables que se utilizaran del estado
const initialState = {
  userData: {},
  status:null,
  successEmail: null,
  role: null,
  error: null,
  loading: false,
  success: false
}

// cambio de estado a inicio de proceso de autenticacion
const authStart = (state, action) => {
  return updateObject(state, {error: null, loading: true, success: false, successEmail: null})
}

// Autenticacion exitosa, cambiar estado a los valores de la sesion
const authSuccess = (state, action) => {
  return updateObject(state, {
    //attributos
    userData: action.userData,
    //authSuccess
    success: "authSuccess",
    //Grupo en el que se encuentra el usuario
    //role: action.tokens.idToken.payload['cognito:groups'],
    //
    status: action.status,
    // error si es que autenticacion falla
    error: null,
    loading: false,
    success: false,
    successEmail: null
  })
}

//Fallo la autenticacion entonces marcar el error
const authFail = (state, action) => {
  if (action.error === 'Invalid code provided, please request a code again.') {
    return updateObject(state, {error: action.error, loading: false, successEmail: "reSendConfirm"})
  } else {
    return updateObject(state, {error: action.error, loading: false, successEmail: null})
  }
}

const stateClean = (state) => {
  return updateObject(state, {error: null, loading: false, success: false, successEmail: null, successForgot: null, status: null})
}
//
const checkEmailSuccess = (state, action) => {
  return updateObject(state, {error: null, loading: false, status: action.status})
}
// llama a los cambios utilizando las accciones como puente
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    //autenticacion - signin
    case actionTypes.AUTH_START: return authStart(state, action)
    case actionTypes.AUTH_SUCCESS: return authSuccess(state, action)
    case actionTypes.AUTH_FAIL: return authFail(state, action)
    
    case actionTypes.STATE_CLEAN: return stateClean(state, action)

    case actionTypes.STATUS_EMAIL_SUCCESS: return checkEmailSuccess(state, action)

    default:
      return state
  }
}

export default authReducer
