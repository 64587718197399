import * as actionTypes from '../../actions/actionTypes'
import { updateObject } from '../utility'
// **  Initial State
const initialState = {
  competitions: {},
  inscriptions: {},
  current: {},
  error: null,
  loading: false
}

const competitionsStart = (state, action) => {
  return updateObject(state, {error: null, loading: true})
}

const competitionsSuccess = (state, action) => {
  
  return updateObject(state, {
    competitions: action.competitions,
    error: null,
    loading: false})
}

const competitionsFail = (state, action) => {
  return updateObject(state, {error: action.error, loading: false})
}

const CompetitionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.COMPETITION_START: return competitionsStart(state, action)
    case actionTypes.COMPETITION_SUCCESS: return competitionsSuccess(state, action)
    case actionTypes.COMPETITION_FAIL: return competitionsFail(state, action)

    default:
      return state
  }
}

export default CompetitionsReducer
