import * as actionTypes from '../../actions/actionTypes'
import { updateObject } from '../utility'


// **  Initial State aqui se establecen las variables que se utilizaran del estado
const initialState = {
  status: false,
  network: false,
  outbox: true,
  mutation: {},
  error: null,
  loading: false
}

// status change when ready
const dtStatus = (state, action) => {
  return updateObject(state, {status: true})
}

const dtNetwork = (state, action) => {
    return updateObject(state, {network: action.network})
}

const dtLoading = (state, action) => {
    return updateObject(state, {loading: action.loading})
}
const dtOutbox = (state, action) => {
    return updateObject(state, {outbox: action.outbox})
}

const dtMutation = (state, action) => {
    return updateObject(state, {mutation: action.mutation, loading: action.loading})
}
// llama a los cambios utilizando las accciones como puente
const datastore = (state = initialState, action) => {
  
  switch (action.type) {
    //autenticacion - signin
    case actionTypes.DATASTORE_READY: return dtStatus(state, action)
    case actionTypes.DATASTORE_NETWORK: return dtNetwork(state, action)
    case actionTypes.DATASTORE_LOADING: return dtLoading(state, action)
    case actionTypes.DATASTORE_OUTBOX: return dtOutbox(state, action)
    case actionTypes.DATASTORE_MUTATION: return dtMutation(state, action)
    default:
      return state
  }
}

export default datastore